@tailwind base;
@tailwind components;

.hero-image{
  background-image: url(./images/opener_1.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0,0,0,0.7);
  height: 700px;
  overflow: hidden;
  animation: change 15s infinite ease-in-out;
}

@keyframes change {
  0%{
    background-image: url(./images/opener_1.webp);  
  }
  20%{
      background-image: url(./images/opener_2.webp);  
    }
  40%{
      background-image: url(./images/opener_3.webp);  
    }
  60%{
      background-image: url(./images/opener_4.webp);  
    }
  80%{
      background-image: url(./images/opener_5.webp);  
    }
  100%{
      background-image: url(./images/opener_1.webp);  
    }
}
.about-image{
  background-image: url(./images/about_1.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0,0,0,0.7);
  background-size: cover;
  height: 700px;
  overflow: hidden;
  animation: change_about 15s infinite ease-out;
}

@keyframes change_about {
  0%{
    background-image: url(./images/about_1.webp);  
  }
  25%{
      background-image: url(./images/about_2.webp);  
      background-position: 30% 30%;
    }
  50%{
      background-image: url(./images/about_3.webp); 
      background-position: 30% 10%; 
    }
  75%{
      background-image: url(./images/about_4.webp);  
    }
  100%{
      background-image: url(./images/about_1.webp);  
    }
}

.widget-image {
  background-image: url(./images/valentine.webp);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  align-items: center;
  height: 300px;
  overflow: hidden;
}

@keyframes breathe {
  from {
    transform: scale(1)
  }
  to {
    transform: scale(1.2)
  }
}  

.breathe-logo {
  animation: breathe 2s infinite ease-in-out alternate;
}

@tailwind utilities;
